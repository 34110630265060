import MouseClick from "@/public/icons/mouse_click.svg";
import DemoTask from "@/app/landing_page/new_land/demo_task";
import React, {useEffect, useState} from "react";
import {Task} from "@/app/dashboard/tasks/models/models";
import {SparklesIcon} from "@heroicons/react/24/solid";


type AnalyticsSectionProps = {
    className?: string,

}

type AnalyticEvent = {
    name: string;
    funnel: string;
};

const analyticEventTitles: AnalyticEvent[] = [
    { name: 'User Signed Up', funnel: 'Acquisition' },
    { name: 'Product Purchased', funnel: 'Conversion' },
    { name: 'Cart Abandoned', funnel: 'Conversion' },
    { name: 'Content Viewed', funnel: 'Engagement' },
    { name: 'Search Performed', funnel: 'Engagement' },
    { name: 'Video Played', funnel: 'Engagement' },
    { name: 'Form Submitted', funnel: 'Conversion' },
    { name: 'Link Clicked', funnel: 'Engagement' },
    { name: 'File Downloaded', funnel: 'Engagement' },
    { name: 'Page Visited', funnel: 'Engagement' },
    { name: 'Notification Received', funnel: 'Engagement' },
    { name: 'Subscription Renewed', funnel: 'Retention' },
    { name: 'Coupon Applied', funnel: 'Conversion' },
    { name: 'Payment Failed', funnel: 'Conversion' },
    { name: 'Feedback Submitted', funnel: 'Engagement' },
    { name: 'Social Share', funnel: 'Engagement' },
    { name: 'Feature Used', funnel: 'Engagement' },
    { name: 'Error Encountered', funnel: 'Engagement' },
    { name: 'Tutorial Completed', funnel: 'Onboarding' },
    { name: 'Upgrade Purchased', funnel: 'Conversion' },
    { name: 'Onboarding Finished', funnel: 'Onboarding' },
    { name: 'Referral Shared', funnel: 'Acquisition' },
    { name: 'Discount Claimed', funnel: 'Conversion' },
    { name: 'Survey Completed', funnel: 'Engagement' },
    { name: 'Live Chat Initiated', funnel: 'Support' },
    { name: 'Wishlist Updated', funnel: 'Engagement' },
    { name: 'Personalization Applied', funnel: 'Engagement' },
    { name: 'Recommendation Clicked', funnel: 'Engagement' },
    { name: 'Loyalty Points Earned', funnel: 'Retention' },
    { name: 'Account Deleted', funnel: 'Churn' },
];
const AnalyticsSection = ({className}: AnalyticsSectionProps) => {

    return (
        <div className={`flex flex-col w-full items-center bg-neutral-100 h-fit ${className}`}>

            <div
                className={"flex flex-col mt-6 text-start lg:items-start lg:text-start z-10"}>
                <div className={"flex flex-row text-center w-fit lg:text-start"}>
                    <p className="text-neutral-900 text-6xl sm:text-7xl xl:text-8xl font-bold md:mb-2">Analytics</p>
                    <MouseClick className={"w-10 sm:w-24 h-fit sm:-rotate-12"}/>
                </div>
                <p className="text-neutral-600 text-4xl sm:text-6xl xl:text-7xl md:mb-2">events</p><br/>
            </div>
            <p className={"text-neutral-600 w-80 text-lg text-center mt-3"}>
                Plan events and funnels. Manage flows and triggers. Link events to UI components.
            </p>
            <div
                className={`relative flex flex-row w-full -mt-24 sm:mt-0 scale-40 sm:scale-75 items-start justify-center gap-3`}>
                <div
                    className="absolute w-[40rem] sm:w-3/4 aspect-2 bg-gradient-to-br from-yellow-300 to-yellow-500 rounded-full filter blur-3xl opacity-80 z-1"/>
                <div className={"flex flex-row mt-20 ms-10 gap-4 z-10"}>

                    <div className={"flex flex-col gap-4"}>
                        {analyticEventTitles.slice(2, 4).map((event, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<AnalyticsChip title={event.name} funnel={event.funnel}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        {analyticEventTitles.slice(0, 2).map((event, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<AnalyticsChip title={event.name} funnel={event.funnel}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        {analyticEventTitles.slice(4, 6).map((event, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<AnalyticsChip title={event.name} funnel={event.funnel}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        {analyticEventTitles.slice(6, 8).map((event, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<AnalyticsChip title={event.name} funnel={event.funnel}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        {analyticEventTitles.slice(8, 10).map((event, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<AnalyticsChip title={event.name} funnel={event.funnel}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        {analyticEventTitles.slice(10, 12).map((event, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<AnalyticsChip title={event.name} funnel={event.funnel}/>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )

}

const AnalyticsChip = ({title, funnel}: { title: string,funnel:string }) => {

    return (
        <div className={"flex flex-col bg-yellow-400 p-4 rounded-2xl shadow-centered w-96 h-fit"}>
            <p className={"text-white text-2xl mb-3 text-nowrap"}>{title}</p>
            <div className={"flex flex-col gap-3"}>
                <div className={"rounded bg-yellow-600 w-34 h-2"}/>
                <div className={"rounded bg-yellow-600 w-64 h-2"}/>
                <div className={"rounded bg-yellow-600 w-44 h-2"}/>
            </div>
            <div className={"flex flex-row justify-between mt-6 h-fit"}>
                <MouseClick className={"w-10 sm:w-8 sm:-rotate-12"}/>
                <p className={"flex items-center p-1 px-3 rounded bg-orange-400 text-white h-fit"}>{funnel}</p>
            </div>

        </div>
    )
}

export default AnalyticsSection
