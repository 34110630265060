// @ts-nocheck
'use client'
import JSONPretty from "react-json-pretty";
import React, { useState, useRef, useEffect } from 'react';

type LandingJsonPreviewProps = {
    className?: string,
    fileName?: string,
    data: any,
}

const LandingJsonPreview = ({className,fileName, data}: LandingJsonPreviewProps) => {

    const theme = {// TODO: -4 default and remove h-48
        main: 'line-height:1.3;color:#f8f8f2;background:#191818;overflow:auto;padding:3',
        error: 'line-height:1.3;color:#f8f8f2;background:#1e1e1e;overflow:auto;',
        key: 'color:#ffffff;',
        string: 'color:#e9fdac;',
        value: 'color:#fdb082;',
        boolean: 'color:#69c;',
    }

    return (
        <div className={`${className} flex flex-col rounded-lg overflow-clip`}>

            <div className={"flex flex-row  sticky top-0 bg-black4 justify-between items-center px-3 py-0.5"}>
                <p className={"px-3 text-white"}>{fileName}</p>
                <div className={"flex flex-row items-center"}>
                    <button
                        className={"px-3 py-0.5 bg-black1 rounded-full h-fit hover:bg-white/20 text-2xs md:text-xs text-white"}>See full object
                    </button>
                    <button className={"text-white px-3 py-1 text-2xs md:text-xs"}>
                        Copy
                    </button>
                </div>

            </div>
            <div
                className={`h-fit overflow-visible`}>
                <JSONPretty themeClassName={`overflow-clip px-4 pt-1 bg-[#191818] scroll-auto h-full text-2xs md:text-xs`} data={data}
                            theme={theme}/>
            </div>
        </div>
    );
};

export default LandingJsonPreview;

//
// type JsonViewerProps = {className?: string ,data: any}
// const JsonViewer = ({className = "h-48",data}:JsonViewerProps) => {
//
//     const theme = {// TODO: -4 default and remove h-48
//         main: 'line-height:1.3;color:#f8f8f2;background:#232323;overflow:auto;padding:3',
//         error: 'line-height:1.3;color:#f8f8f2;background:#1e1e1e;overflow:auto;',
//         key: 'color:#ffffff;',
//         string: 'color:#e9fdac;',
//         value: 'color:#fdb082;',
//         boolean: 'color:#69c;',
//     }
//
//     return (<JSONPretty themeClassName={`overflow-clip rounded-lg p-4 bg-[#232323] scroll-auto ${className}`} data={data} theme={theme}/>)
//
// }
//
// export default JsonViewer
