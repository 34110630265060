import {ReactSVG} from "react-svg";
import React from "react";
import {SparklesIcon} from "@heroicons/react/24/solid";

type FooterProps = {
    className?:string,

}
const Footer = ({className}:FooterProps) => {
    return (
        <div className={`flex flex-row bg-neutral-800 ${className}`}>
            <img
                className=" max-w-none h-10 order-0"
                src="/icons/logo.svg"
                alt=""
            />

        </div>
    )
}

export default Footer
