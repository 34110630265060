'use client'

import React, {useEffect, useState} from "react";

import DociPopup from "@/app/components/doci_popup";

import {app, db} from "@/app/firebase";
import {addDoc, collection} from "@firebase/firestore";
import {getAnalytics, logEvent} from "@firebase/analytics";

const JoinTheBetaPopup = ({isOpen, handleClose}: {
    isOpen: boolean,
    handleClose: () => void,
}) => {

    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [name, setName] = useState<string>('');

    const [isEnable, setIsEnable] = useState(false)

    const handleOnSubmit = async () => {
        const analytics = getAnalytics(app);

        logEvent(analytics, 'click_submit_join_beta');

        try {
            const docRef = await addDoc(collection(db, 'betaWaitlist'), {
                name,
                email,
                phone,
                company
            });
            console.log('Document written with ID: ', docRef.id);
        } catch (e) {
            console.error('Error adding document: ', e);
        }
        alert("We will contact you soon!")
        handleClose()
    }

    useEffect(() => {
        if (email && name) {
            setIsEnable(true)
        } else {
            setIsEnable(false)
        }
    }, [name, email]);

    return (
        <DociPopup className={"flex flex-col items-start max-w-xl"} show={isOpen} onClose={handleClose}>
            <div className={"text-2xl text-white"}>Join The Beta</div>

            <p className={"text-white mb-1 text-xs mt-3"}>Name*</p>
            <input
                className={`w-full doci-input`}
                type="text"
                id="name"
                placeholder={"e.g John Smith"}
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setName(event.target.value)
                }}
            />
            <p className={"text-white mb-1 text-xs mt-3"}>Mail*</p>
            <input
                className={`w-full doci-input`}
                type="text"
                id="email"
                placeholder={"e.g John.Smith@gmail.com"}
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value)
                }}
            />
            <p className={"text-white mb-1 text-xs mt-3"}>Company</p>
            <input
                className={`w-full doci-input`}
                type="text"
                id="company"
                placeholder={"Your company name"}
                value={company}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCompany(event.target.value)
                }}
            />
            <p className={"text-white mb-1 text-xs mt-3"}>Phone</p>
            <input
                className={`w-full doci-input`}
                type="text"
                id="phone"
                placeholder={"+1 (123) 456-7890"}
                value={phone}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPhone(event.target.value)
                }}
            />
            <button
                disabled={!isEnable}
                className={"doci-button text-base text-white mt-6 self-end group"}
                onClick={handleOnSubmit}>
                <div className={"flex flex-row gap-2 items-center"}>
                    Join the Beta!
                </div>
            </button>

        </DociPopup>
    )

}

export default JoinTheBetaPopup
