import AIPrompt from "@/public/images/landing_page/ai_prompt.svg";
import DemoTask from "@/app/landing_page/new_land/demo_task";
import React, {useEffect, useState} from "react";
import {Task} from "@/app/dashboard/tasks/models/models";
import {SparklesIcon} from "@heroicons/react/24/solid";


type AISectionProps = {
    className?: string,

}
const AISection = ({className}: AISectionProps) => {
    const [tasks, setTasks] = useState<Task[]>([])

    useEffect(() => {
        const data: Task[] = require('@/app/landing_page/mocks/task_card_mocks.json');
        setTasks(data)
    }, []);
    function isSafari() {
        if (typeof window === 'undefined') return false
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
    const classItem = isSafari()?'':'hover:scale-105 duration-700'
    return (
        <div className={`flex flex-col w-full items-center bg-neutral-100 h-fit pt-20 sm:pt-52 ${className}`}>

            <div className={"w-full flex flex-col sm:flex-row justify-center items-center sm:gap-8 "}>
                <AIPrompt className={"order-2 sm:order-1 w-64 h-fit sm:-rotate-12"}/>
                <div className={"order-1 sm:order-2 flex flex-col mt-6 text-start items-center lg:items-start lg:text-start z-10"}>
                    <div className={"flex flex-row text-center w-fit lg:text-start"}>
                        <p className="text-neutral-900 text-6xl sm:text-7xl xl:text-8xl font-bold md:mb-2">AI</p><br/>
                        <SparklesIcon className={"-ms-4 size-14 md:size-20 fill-purple-500 stroke-black"}/>
                    </div>
                    <p className="text-neutral-900 text-4xl sm:text-6xl xl:text-7xl md:mb-2">Task Generator</p><br/>
                </div>
            </div>
                <p className={"text-neutral-600 w-80 text-lg text-center mt-3"}>
                    Describe your next development, and we'll effortlessly generate the necessary tasks.
                </p>
            <div className={`relative flex flex-row w-full -mt-24 sm:mt-0 scale-40 sm:scale-75 items-start justify-center gap-3 shadow-none`}>
                <div className="absolute w-[40rem] sm:w-3/4 aspect-2 bg-gradient-to-br from-purple-600 to-blue-700 rounded-full filter blur-3xl opacity-80 z-1"/>
                <div className={"flex flex-row mt-20 ms-10 gap-2 z-10"}>
                    <div className={"flex flex-col gap-2"}>
                        {tasks.slice(0, 2).map((subtask, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<DemoTask className={`${classItem} w-96`} item={subtask}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        {tasks.slice(2, 4).map((subtask, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<DemoTask className={`${classItem} w-96`} item={subtask}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        {tasks.slice(4, 6).map((subtask, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<DemoTask className={`${classItem} w-96`} item={subtask}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        {tasks.slice(6, 8).map((subtask, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<DemoTask className={`${classItem} w-96`} item={subtask}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        {tasks.slice(8, 10).map((subtask, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<DemoTask className={`${classItem} w-96`} item={subtask}/>)
                        })}
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        {tasks.slice(10, 12).map((subtask, i) => { //This is doplicate on purpose because of the endless loog animation
                            return (<DemoTask className={`${classItem} w-96`} item={subtask}/>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AISection
