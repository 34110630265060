import {ArrowRightIcon, SparklesIcon} from "@heroicons/react/24/solid";
import React, {MutableRefObject, RefObject} from "react";
import {useRouter} from "next/navigation";

type IntroSectionProps = {
    className?: string,
    onClick: () => void,
    mainRef?: RefObject<HTMLDivElement>
}
const IntroSection = ({className, onClick, mainRef}: IntroSectionProps) => {

    // const router = useRouter()

    return (<div className={`flex flex-col lg:flex-row lg:gap-32 items-center ${className}`}>
            <img
                className=" max-w-none w-52 sm:w-64 order-0"
                src="/icons/logo.svg"
                alt=""/>

            <div ref={mainRef} className={"flex flex-col items-center lg:items-start mt-20 sm:mt-16"}>
                <p className="text-sm md:text-lg inline-block font-semibold" style={{
                    background: 'linear-gradient(90deg, #DA22FF, #9f35ff)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                }}>Refined For Developers</p>

                <div className={"flex flex-col mt-6 text-center items-center lg:items-start lg:text-start"}>
                    <div className={"flex flex-row text-center w-fit lg:text-start"}>
                        <p className="text-white text-6xl sm:text-7xl xl:text-8xl font-bold md:mb-2">Your AI</p><br/>
                        <SparklesIcon className={"-ms-4 size-14 md:size-20 fill-purple-500 stroke-black"}/>
                    </div>
                    <p className="text-white text-4xl sm:text-6xl xl:text-7xl md:mb-2">Task Management</p><br/>
                </div>
                <p className={"mt-10 px-10 text-white lg:px-0 text-xl xl:text-3xl text-center lg:text-start md:w-128 xl:w-135 font-extralight"}>
                    Streamline your development workflow with our task management solution.
                    Track changes across APIs, analytic events, UI components, states, and logic.
                </p>
                <button
                    className="flex flex-row mt-12 items-center gap-3 bg-purple-600 hover:bg-purple-700 px-6 sm:px-24 py-3 rounded-xl"
                    onClick={onClick}>
                    <SparklesIcon className="size-6 fill-neutral-50"/>
                    <p className={"text-neutral-50 text-xl "}>
                        Join the Beta
                    </p>
                    <SparklesIcon className="size-6 fill-neutral-50"/>
                </button>
                {/*<div className={"flex flex-col sm:flex-row gap-3 mt-10"}>*/}
                {/*    <button*/}
                {/*        className="flex flex-row items-center gap-2 bg-neutral-50 px-6 py-3 rounded-xl"*/}
                {/*        onClick={onClick}>*/}
                {/*        <p className={"text-neutral-900 text-base"}>*/}
                {/*            Join the Beta*/}
                {/*        </p>*/}
                {/*        <ArrowRightIcon className="size-4 fill-neutral-900"/>*/}
                {/*    </button>*/}
                {/*    <a*/}
                {/*        href="mailto:talmorid@gmail.com"*/}
                {/*        className="flex flex-row justify-center border-2 border-neutral-50 gap-2 px-6 py-3 rounded-2xl"*/}
                {/*    >*/}
                {/*        <p className="text-neutral-50 text-base">*/}
                {/*            Contact Us*/}
                {/*        </p>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
    )

}

export default IntroSection
