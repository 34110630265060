
import React from "react";

type ComponentSectionProps = {
    className?:string,

}
const ComponentSection = ({className}:ComponentSectionProps) =>{

    return (<div className={`flex flex-col w-full bg-neutral-100 items-center pb-10 ${className}`}>
        <div
            className={"absolute sm:mt-44 flex flex-col mt-32 text-start items-center lg:items-center lg:text-start"}>
            <p className=" text-neutral-900 text-4xl sm:text-7xl font-semibold ">UI Components</p>
            <p className="sm:ms-128 text-neutral-600 text-4xl font-light"><span className={"text-lg"}>and</span> States
            </p>
            <p className="text-neutral-600 w-full sm:w-128 text-lg sm:text-2xl font-light px-10 text-center mt-10 sm:mt-20">
                Manage, Edit & Organize your components and states</p>
        </div>
        <div className={"relative w-full h-128 sm:w-1/2 ms-10"}>
            <img
                className="absolute rotate-12 right-6 sm:right-0 w-44 sm:w-80 object-cover z-10"
                src={`/images/landing_page/empty.svg`}
                alt="API Management"
            />
            <img
                className="absolute left-0 -rotate-6 w-32 sm:w-44 object-cover z-10"
                src={`/images/landing_page/error.svg`}
                alt="API Management"
            />

        </div>
        <img
            className="-mt-44 sm:mt-0 px-10 w-full sm:w-1/2 max-w-[38rem] object-cover z-10"
            src={`/images/landing_page/data.svg`}
            alt="API Management"
        />

    </div>)

}

export default ComponentSection
