type IntroFooterProps = {
    className?: string,

}
const IntroFooter = ({className}: IntroFooterProps) => {

    return (<div className={`flex flex-col sm:flex-row gap-10 ${className}`}>
        <div className={"w-80 text-start sm:text-center"}>
            <p className={"text-lg font-semibold text-white"}>Automated documentation</p>
            <p className={"mt-3 text-base font-light text-neutral-400"}>Effortlessly generate and maintain up to date
                product documentation.</p>
        </div>
        <div className={"w-80 text-start sm:text-center"}>
            <p className={"text-lg font-semibold text-white"}>AI Tasks Generator</p>
            <p className={"mt-3 text-base font-light text-neutral-400"}>Use AI to create development tasks that meet new
                product requirements efficiently and effectively.</p>
        </div>
        <div className={"w-80 text-start sm:text-center"}>
            <p className={"text-lg font-semibold text-white"}>Development workflow</p>
            <p className={"mt-3 text-base font-light text-neutral-400"}>Organize, watch, and streamline tasks for API
                changes, analytics events, UI components, and more.</p>
        </div>
    </div>)

}

export default IntroFooter
