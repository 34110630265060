import {useRouter} from "next/navigation";
import {ArrowRightIcon, SparklesIcon} from "@heroicons/react/24/solid";
import MySvg from "@/public/icons/logo.svg";

import React from "react";

enum BannerButtons {
    Main, AI, API, Analytics, Component,Join
}

type BannerProps = {
    className?: string,
    onClick:(arg:BannerButtons) =>void

}
const Banner = ({className, onClick}: BannerProps) => {

    const router = useRouter()

    return (<div
        className={"flex flex-row gap-2 fixed top-1 items-center justify-between sm:top-4 lg:top-5 bg-neutral-200/95 z-[999] mx-3 p-2 px-4s w-90 md:w-fit rounded-xl"}>
        <button className={"flex flex-row gap-1 items-center mx-3"} onClick={()=>onClick(BannerButtons.Main)}>
            <MySvg className={"size-6 fill-neutral-900"}/>
            <p className={"text-neutral-900 font-semibold text-"}>DoCi</p>
        </button>
        <div className={"flex flex-row w-fit gap-2 hidden md:flex"}>
            <button className={"flex flex-row gap-1 items-center hover:bg-neutral-400/20 px-3 py-2 rounded-xl"}
                    onClick={() => onClick(BannerButtons.AI)}>
                <SparklesIcon className={"size-4 fill-purple-500 stroke-black rotate-180s"}/>
                <p className={"text-neutral-900 text-sm"}>AI Task Generator</p>

            </button>
            <button className={"hover:bg-neutral-400/20 px-3 py-2 rounded-xl"}
                    onClick={() => onClick(BannerButtons.API)}>
                <p className={"text-neutral-900 text-sm"}>API</p>
            </button>
            <button className={"hover:bg-neutral-400/20 px-3 py-2 rounded-xl mx-2"}
                    onClick={() => onClick(BannerButtons.Component)}>
                <p className={"text-neutral-900 text-sm"}>UI Component</p>
            </button>
            <button className={"hover:bg-neutral-400/20 px-3 py-2 rounded-xl"}
                    onClick={() => onClick(BannerButtons.Analytics)}>
                <p className={"text-neutral-900 text-sm"}>Analytics Event</p>
            </button>
        </div>

        <button
            className="flex flex-row items-center gap-1 bg-purple-600 hover:bg-purple-700 px-3 py-2 rounded-xl"
            // onClick={async () => {router.push('/login')}}
            onClick={() => onClick(BannerButtons.Join)}>
            <SparklesIcon className="size-4 fill-neutral-50"/>
            <p className={"text-neutral-50 text-sm"}>
                Join the Beta
            </p>
            <SparklesIcon className="size-4 fill-neutral-50"/>
        </button>

    </div>)

}

export {BannerButtons, Banner}
