'use client'
import {Banner, BannerButtons} from "./landing_page/new_land/banner";
import {config} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import React, {useEffect, useRef, useState} from "react";

import AISection from "@/app/landing_page/new_land/ai_section";
import Footer from "@/app/landing_page/old_landing/components/footer";
import IntroSection from "@/app/landing_page/new_land/intro_section";
import TasksTypeSlide from "@/app/landing_page/new_land/tasks_type_slide";
import IntroFooter from "@/app/landing_page/new_land/intro_footer";
import APISection from "@/app/landing_page/new_land/api_section";
import ComponentSection from "@/app/landing_page/new_land/component_section";
import AnalyticsSection from "@/app/landing_page/new_land/analytics_section";
import JoinTheBetaPopup from "@/app/landing_page/new_land/join_the_beta_popup";
import {app} from "@/app/firebase";
import {Analytics, getAnalytics, logEvent} from "@firebase/analytics";
import {setAnalyticsCollectionEnabled} from 'firebase/analytics';
import {useClientMediaQuery} from "@/app/services/utils/use_client_media_query";

config.autoAddCss = false

export default function Home() {
    const mainRef = useRef<HTMLDivElement>(null);
    const aiRef = useRef<HTMLDivElement>(null);
    const apiRef = useRef<HTMLDivElement>(null);
    const componentsRef = useRef<HTMLDivElement>(null);
    const analyticsRef = useRef<HTMLDivElement>(null);

    const [joinPopup, setJoinPopup] = useState(false)

    const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)

    const isMobile = useClientMediaQuery('(max-width: 640px)')

    useEffect(() => {
        if (typeof window === 'undefined') return
        const analytics = getAnalytics(app);
        setAnalyticsCollectionEnabled(analytics, true);
        logEvent(analytics, 'visit_new_landing');
        setAnalytics(analytics)
    }, []);

    const handleBannerClick = (arg: BannerButtons) => {
        if (typeof window === 'undefined' || analytics == undefined) return

        switch (arg) {
            case BannerButtons.Main:
                if (mainRef.current) {
                    logEvent(analytics, 'click_main_banner');
                    mainRef.current.scrollIntoView({behavior: 'smooth'});
                }
                break;
            case BannerButtons.AI:
                if (aiRef.current) {
                    logEvent(analytics, 'click_ai_banner');
                    aiRef.current.scrollIntoView({behavior: 'smooth'});
                }
                break;
            case BannerButtons.API:
                if (apiRef.current) {
                    logEvent(analytics, 'click_api_banner');
                    apiRef.current.scrollIntoView({behavior: 'smooth'});
                }
                break;
            case BannerButtons.Component:
                if (componentsRef.current) {
                    logEvent(analytics, 'click_component_banner');
                    componentsRef.current.scrollIntoView({behavior: 'smooth'});
                }
                break;
            case BannerButtons.Analytics:
                if (analyticsRef.current) {
                    logEvent(analytics, 'click_analytics_banner');
                    analyticsRef.current.scrollIntoView({behavior: 'smooth'});
                }
                break;
            case BannerButtons.Join:
                logEvent(analytics, 'click_join_beta_banner');
                setJoinPopup(true)
                break;
        }

    }

    const handleJoinBeta = () => {
        setJoinPopup(value => !value)
    }

    return (
        <main ref={isMobile?undefined:mainRef} className="relative flex flex-col overflow-hidden items-center bg-zinc-900">
            <Banner className={"absolute"} onClick={handleBannerClick}/>
            <IntroSection mainRef={isMobile?mainRef:undefined} className={"pt-28 md:pt-48 lg:pt-28"} onClick={() => {
                if (analytics) {
                    logEvent(analytics, 'click_join_beta_from_section');
                }
                handleJoinBeta()
            }}/>
            <IntroFooter className={"mt-32"}/>
            <TasksTypeSlide className={"mt-20"}/>
            <div className={"w-full"} ref={aiRef}><AISection className={"sm:pb-44"}/></div>
            <div className={"w-full"} ref={apiRef}>
                <APISection className={"sm:pt-20 sm:pb-44"}/></div>
            <div className={"w-full"} ref={componentsRef}>
                <ComponentSection className={"pt-32 sm:pt-20 sm:pb-60"}/>
            </div>
            <div className={"w-full"} ref={analyticsRef}>
                <AnalyticsSection className={"pt-20 sm:pt-20 sm:pb-40"}/>
            </div>

            <Footer className={"w-full p-5 pt-10"}/>
            <JoinTheBetaPopup isOpen={joinPopup} handleClose={handleJoinBeta}/>

        </main>
    )
}


//npm run dev
