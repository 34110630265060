import React from "react";
import {ChartBarIcon, SparklesIcon, UsersIcon} from "@heroicons/react/24/solid";
import {CogIcon} from "@heroicons/react/24/outline";
import {APIObjectTask, Task, TaskType, TeamType} from "@/app/dashboard/tasks/models/models";
import ComponentsIcon from "@/public/icons/components_icon.svg";
import {CodeBracketIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import MagicWand from "@/public/icons/magic_wand.svg";
import StateIcon from "@/public/icons/ui_state.svg";
import LandingJsonPreview from "@/app/landing_page/old_landing/components/landing_json_viewer";

type DemoTaskProps = {
    className?:string,
    item:Task
}
const DemoTask = ({className, item}:DemoTaskProps) =>{
    return (
        <div
            className={`flex flex-col border-2 lg:border shadow-centered border-neutral-500 bg-neutral-100 rounded-xl text-black1 p-4 h-fit hover:bg-white/90 hover:cursor-pointer ${className}`}>
            <div className={"flex flex-row gap-0 md:gap-5 justify-between items-start"}>

                <div className={"flex flex-row gap-1 items-start justify-center"}>
                    {item.task_type == TaskType.ANALYTICS && <AnalyticsChip/>}
                    {item.task_type == TaskType.UIComponent && <UiComponentChip/>}
                    {item.task_type == TaskType.UIState && <UiStateChip/>}
                    {item.task_type == TaskType.LOGIC && <LogicChip/>}
                    {item.task_type == TaskType.PublicAPI && <APIChip/>}
                    {item.task_type == TaskType.API_OBJECT && <APIObjectChip/>}
                    {item.task_type == TaskType.API_QUERY && <APIQueryChip/>}

                </div>
                <div className={"flex flex-row gap-1 items-center"}>
                    {item.craft && <CraftChip/>}
                    {item.ai_created && <SparklesIcon className={"size-3 md:size-4 fill-purple-500"}/>}
                    {item.team_type == TeamType.BACKEND && <div
                        className={"flex flex-row gap-1 bg-fuchsia-900 px-2 py-1s w-fit justify-self-end items-center rounded"}>
                        <UsersIcon className={`size-3 fill-white`}/>
                        <p className={"text-white text-2xs md:text-sm"}>Backend</p>
                    </div>}
                    {item.team_type == TeamType.FRONTEND && <div
                        className={"flex flex-row gap-1 bg-fuchsia-600 px-2 py-1s w-fit justify-self-end items-center rounded"}>
                        <UsersIcon className={`size-3 fill-white`}/>
                        <p className={"text-white text-2xs md:text-sm"}>Frontend</p>
                    </div>}
                </div>
            </div>

            <p className={" mt-3 text-sm md:text-base text-neutral-900"}>{item.title}</p>

            <p className={"mt-1 text-2xs md:text-sm font-light text-gray-800"}>{item.description}</p>
            {item.task_type == TaskType.API_OBJECT && <ApiObBody item={item}/>}
            {item.task_type == TaskType.API_QUERY && <ApiQueryBody item={item}/>}

        </div>

    )
}

const ApiObBody = ({item}:{item:Task}) => {
    const obj = item as APIObjectTask
    return <> {obj.data && <LandingJsonPreview className={"mt-3 max-h-32"} data={obj.data}/>}</>
}

const ApiQueryBody = ({item}:{item:Task}) => {
    const obj = item as APIObjectTask
    return <div className={"bg-gray-200 px-4 py-2 text-xs mt-3 rounded-lg"}>{obj.data}</div>
}

const CraftChip = () =>{
    return (
        <MagicWand className={"w-3 md:w-4 fill-blue-500"}/>
  )
}

const AnalyticsChip = () => {
    return (<div className={"flex flex-row gap-1 bg-amber-400 px-2 items-center rounded"}>
        <ChartBarIcon className={`size-3 fill-white`}/>
        <p className={"text-white text-2xs md:text-sm"}>Analytics event</p>
    </div>)
}

const UiComponentChip = () => {
    return (<div className={"flex flex-row gap-1 bg-emerald-500 px-2 py-1s items-center rounded"}>
        <ComponentsIcon className={"size-3"}/>
        <p className={"text-white text-2xs md:text-sm"}>UI Component</p>
    </div>)
}

const UiStateChip = () => {
    return (<div className={"flex flex-row gap-1 bg-emerald-600 px-2 py-1s items-center rounded"}>
        <StateIcon className={"size-4 stroke-white"}/>
        <p className={"text-white text-2xs md:text-sm"}>UI State</p>
    </div>)
}

const LogicChip = () => {
    return (<div className={"flex flex-row gap-1 bg-sky-500 px-2 py-1s items-center rounded"}>
        <CogIcon className={"size-4 stroke-white"}/>
        <p className={"text-white text-2xs md:text-sm"}>Logic</p>
    </div>)
}

const APIChip = () => {
    return (<div className={"flex flex-row gap-1 bg-orange-500 px-2 py-1s items-center rounded"}>
        <img src="/icons/api_icon.svg" alt="brand" className="size-3"/>
        <p className={"text-white text-2xs md:text-sm"}>Public API</p>
    </div>)
}

const APIObjectChip = () => {
    return (<div className={"flex flex-row gap-1 bg-stone-500 px-2 py-1s items-center rounded"}>
        <CodeBracketIcon className={"size-3 md:size-4 fill-white"}/>
        <p className={"text-white text-2xs md:text-sm"}>API Object</p>
    </div>)
}

const APIQueryChip = () => {
    return (<div className={"flex flex-row gap-1 bg-stone-600 px-2 py-1s items-center rounded"}>
        <MagnifyingGlassIcon className={"size-3 md:size-4 fill-white"}/>
        <p className={"text-white text-2xs md:text-sm"}>API Query</p>
    </div>)
}

export default DemoTask
