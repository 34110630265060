'use client'

import React, {useEffect, useState} from "react";
import {chips, shuffledChips, shuffledChips2} from "@/app/landing_page/new_land/tasks_type_chips";
import {shuffleArray} from "@/app/services/ts_utils";

type TasksTypeSlideProps = {
    className?: string,

}
const TasksTypeSlide = ({className}: TasksTypeSlideProps) => {


    return (
        <div className={`flex flex-col w-full items-center bg-neutral-100 h-fit pt-20 sm:pt-48 rounded-t-3xl ${className}`}>
            <p className={"text-neutral-900 text-3xl sm:text-5xl pb-24 text-center"}>
                <span className={"font-semibold"}>Perfectly aligning</span><br/>
                 with your development cycles.
            </p>
            <div className={`scroll-container-new`}>
                <div>
                    <div className="content flex flex-row gap-4 px-5 sm:px-20">
                        {shuffledChips.map((Chip, index) => (<Chip key={index}/>))}
                        {shuffledChips.map((Chip, index) => (<Chip key={index}/>))}
                        {shuffledChips.map((Chip, index) => (<Chip key={index}/>))}
                    </div>
                </div>
            </div>

            <div className={`scroll-container-new`}>
                <div>
                    <div className="content-reverse flex flex-row gap-4 px-5 sm:px-20">
                        {shuffledChips2.map((Chip, index) => (<Chip key={index}/>))}
                        {shuffledChips2.map((Chip, index) => (<Chip key={index}/>))}
                        {shuffledChips2.map((Chip, index) => (<Chip key={index}/>))}
                    </div>
                </div>
            </div>
            <div className={`scroll-container-new`}>
                <div>
                    <div className="content flex flex-row gap-4 px-5 sm:px-20">
                        {chips.map((Chip, index) => (<Chip key={index}/>))}
                        {chips.map((Chip, index) => (<Chip key={index}/>))}
                        {chips.map((Chip, index) => (<Chip key={index}/>))}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TasksTypeSlide
