import {APIObjectTask, Task} from "@/app/dashboard/tasks/models/models";
import LandingJsonPreview from "@/app/landing_page/old_landing/components/landing_json_viewer";
import MagicWand from "@/public/icons/magic_wand.svg";
import APIIcon from "@/public/icons/api_icon.svg";
import {BugAntIcon, ChartBarIcon, RocketLaunchIcon, WrenchIcon} from "@heroicons/react/24/solid";
import ComponentsIcon from "@/public/icons/components_icon.svg";
import StateIcon from "@/public/icons/ui_state.svg";
import {CogIcon} from "@heroicons/react/24/outline";
import {BeakerIcon, CodeBracketIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import React from "react";

const ApiObBody = ({item}: { item: Task }) => {
    const obj = item as APIObjectTask
    return <> {obj.data && <LandingJsonPreview className={"mt-3 max-h-32"} data={obj.data}/>}</>
}

const ApiQueryBody = ({item}: { item: Task }) => {
    const obj = item as APIObjectTask
    return <div className={"bg-gray-200 px-4 py-2 text-xs mt-3 rounded-lg"}>{obj.data}</div>
}

const CraftChip = () => {
    return (
        <MagicWand className={"w-3 sm:w-4 fill-blue-500"}/>
    )
}

const AnalyticsChip = () => {
    return (<div className={"flex flex-row gap-1 bg-amber-400 px-6 sm:gap-4 py-2 sm:py-3 sm:rounded-2xl items-center rounded-xl "}>
        <ChartBarIcon className={`size-5 sm:size-8 fill-white`}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Analytics event</p>
    </div>)
}

const UiComponentChip = () => {
    return (<div className={"flex flex-row gap-1 bg-emerald-500 px-6 items-center rounded-xl sm:gap-4  sm:py-3 sm:rounded-2xl"}>
        <ComponentsIcon className={"size-5 sm:size-8"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>UI Component</p>
    </div>)
}

const UiStateChip = () => {
    return (<div className={"flex flex-row gap-1 bg-emerald-600 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <StateIcon className={"size-6 sm:size-10 stroke-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>UI State</p>
    </div>)
}

const LogicChip = () => {
    return (<div className={"flex flex-row gap-1 bg-sky-500 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <CogIcon className={"size-6 sm:size-10 stroke-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Logic</p>
    </div>)
}

const APIChip = () => {
    return (<div className={"flex flex-row gap-1 bg-orange-500 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <APIIcon className={"size-5 sm:size-7 stroke-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Public API</p>
    </div>)
}

const APIObjectChip = () => {
    return (<div className={"flex flex-row gap-1 bg-stone-500 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <CodeBracketIcon className={"size-5 sm:size-8 fill-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>API Object</p>
    </div>)
}

const APIQueryChip = () => {
    return (<div className={"flex flex-row gap-1 bg-stone-600 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <MagnifyingGlassIcon className={"size-3 sm:size-8 fill-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>API Query</p>
    </div>)
}

const BugChip = () => {
    return (<div className={"flex flex-row gap-1 bg-red-600 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <BugAntIcon className={"size-3 sm:size-8 fill-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Bug</p>
    </div>)
}
const RefactoringChip = () => {
    return (<div className={"flex flex-row gap-1 bg-blue-1 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <WrenchIcon className={"size-3 sm:size-8 fill-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Refactoring</p>
    </div>)
}

const KickoffChip = () => {
    return (<div className={"flex flex-row gap-1 bg-yellow-400 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <RocketLaunchIcon className={"size-3 sm:size-8 fill-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Kickoff</p>
    </div>)
}

const TestChip = () => {
    return (<div className={"flex flex-row gap-1 bg-pink-500 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <BeakerIcon className={"size-3 sm:size-8 fill-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Test</p>
    </div>)
}

const IntegrationChip = () => {
    return (<div className={"flex flex-row gap-1 bg-amber-500 px-6 items-center rounded-xl sm:gap-4 sm:py-3 sm:rounded-2xl"}>
        <CodeBracketIcon className={"size-3 sm:size-8 fill-white"}/>
        <p className={"text-white text-base sm:text-2xl whitespace-nowrap"}>Integration</p>
    </div>)
}

const chips = [
    AnalyticsChip,
    UiComponentChip,
    UiStateChip,
    LogicChip,
    APIChip,
    APIObjectChip,
    APIQueryChip,
    BugChip,
    RefactoringChip,
    KickoffChip,
    TestChip,
    IntegrationChip
]

const shuffledChips = [
    IntegrationChip,
    UiStateChip,
    APIQueryChip,
    BugChip,
    LogicChip,
    KickoffChip,
    AnalyticsChip,
    RefactoringChip,
    APIObjectChip,
    TestChip,
    UiComponentChip,
    APIChip,
];

const shuffledChips2 = [
    IntegrationChip,
    UiStateChip,
    APIQueryChip,
    BugChip,
    LogicChip,
    KickoffChip,
    AnalyticsChip,
    RefactoringChip,
    APIObjectChip,
    TestChip,
    UiComponentChip,
    APIChip,
];

export {
    ApiObBody,
    ApiQueryBody,
    CraftChip,
    AnalyticsChip,
    UiComponentChip,
    UiStateChip,
    LogicChip,
    APIChip,
    APIObjectChip,
    APIQueryChip,
    chips,
    shuffledChips,
    shuffledChips2
}
