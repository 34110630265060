import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";

type DociPopupProps = {
    className?: string,
    children?: React.ReactNode,
    show: boolean,
    onClose: () => void
}

const DociPopup = ({className, children, show, onClose}: DociPopupProps) => {

    return (<Transition appear show={show} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-neutral-950/40"/>
                </Transition.Child>

                <div className="fixed inset-0">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={`w-full max-h-[90vh] transform rounded-lg bg-neutral-900 border border-neutral-800 p-6 shadow-sm transition-all ml-72s mb-32 ${className}`}>
                                {children}

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default DociPopup
