import {ApiData} from "@/app/dashboard/api/api_provider";


type PromptObject = {
    _id: { $oid: string },
    prompt: string
}

//TODO: deprecate this all file
enum EntityType {
    PublicAPI = "PUBLIC_API",
    UIComponent = "UI_COMPONENT",
}

type DociEntity = {
    _id: { $oid: string },
    created_task: string,
    name: string,
    tasks: string[],
    type: EntityType
}

type APIEntity = DociEntity & {
    api_data: ApiData,
}

type ComponentEntity = DociEntity & {
    states: string[],
}


enum TeamType {
    FRONTEND = "FRONTEND",
    BACKEND = "BACKEND",
    BOTH = "BOTH",
}

enum TaskType {
    TEXT = 'TEXT',
    PublicAPI = "PUBLIC_API",
    API_OBJECT = "API_OBJECT",
    API_QUERY = "API_QUERY",
    UIComponent = "UI_COMPONENT",
    UIState = "UI_STATE",
    LOGIC = "LOGIC",
    ANALYTICS = "ANALYTICS"
}

type ParentTask = {
    _id: string,
    task_type: TaskType
}

type Task = {
    _id: { $oid: string },
    craft: boolean, //TODO: for now, we don't have this on every task - this is for ui check
    is_checked: boolean, //TODO: only in the app
    ai_created: boolean,
    prompt?: PromptObject,
    task_type: TaskType,
    team_type: TeamType,
    title: string,
    is_sub_task: boolean,
    description: string,
    subtasks?: string[],
    selected_sub_task?: Record<TeamType, number>// TODO: change this
    parent_task?: ParentTask //TODO: only here for filtering
};

type BoardTask = Task & {
    column:string,
    test:string
}

type SubTask = Task & {
    parent_task: ParentTask
}

type APITask = Task & {//task_type=PUBLIC_API - same for modifying and adding
    api_data: ApiData,
    color: string, //TODO: remove
    craft: boolean,
    mongo_id: string //if not crafting - the api entity
    related_mongo_id: string //if crafting - the api entity which similar (for object response)
};

type APIObjectTask = Task &{ //TODO: API_OBJECT - make it more specific and also should extend subtask - maybe parent task can be in any task object
    data?:string
}

type APISubTask = SubTask & { //logic task-type = LOGIC
    api_change_type: string //TODO: fix
    data: any,
}

type ComponentTask = Task & { //type - UI_COMPONENT
    color: string, //TODO: remove
    craft: boolean,
    component_name: string,
    states: string[];
};

type ComponentSubTask = SubTask & {// task-type - UI_STATE
    craft: boolean,
    state: string,
}

type ComponentLogicTask = Task & { //logic task-type = LOGIC 1-fetch data from api 2- navigate between states
    parent_task: ParentTask
}


type AnalyticsTask = Task & { //task-type - ANALYTICS //TODO: add analytics entity && add component linkage
    craft: boolean,
    attributes: any,
    parent_task: ParentTask
}

export {TaskType, TeamType}
export type {
    Task,
    BoardTask,
    SubTask,
    APITask,
    APISubTask,
    ComponentTask,
    ComponentSubTask,
    AnalyticsTask,
    APIEntity,
    ComponentEntity,
    PromptObject,
    APIObjectTask
}
