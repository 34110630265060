import AIPrompt from "@/public/images/landing_page/ai_prompt.svg";
import {SparklesIcon} from "@heroicons/react/24/solid";
import React from "react";

type APISectionProps = {
    className?:string,

}
const APISection = ({className}:APISectionProps) =>{

    return (<div className={`flex flex-col w-full bg-neutral-100 items-center pb-10 ${className}`}>
        <div
            className={"flex flex-col mt-6 text-start items-center lg:items-start lg:text-start z-10"}>
            <p className="text-neutral-600 text-4xl sm:text-4xl font-light">Manage your</p><br/>
            <p className="sm:ms-40 text-neutral-900 text-6xl sm:text-7xl font-semibold">APIs</p><br/>
        </div>
        <div className={"flex flex-col sm:flex-row gap-14 sm:gap-3 w-full justify-center items-center mt-16 container"}>
            <p className="text-neutral-600 w-96 text-xl sm:text-3xl font-light px-10 text-center sm:text-start">
                Our platform makes it easy to manage your API endpoints.
                Track, update, and control them all in one place.</p>

            <div className={"relative w-96 sm:w-1/2 rotate-2 ms-10"}>

                <img
                    className="rounded-xl object-cover z-10 relative"
                    src={`/images/landing_page/api.png`}
                    alt="API Management"
                />
                <div
                    className=" top-0 absolute w-[40rem] sm:w-[60rem] aspect-2 bg-gradient-to-br from-orange-500 to-purple-700 rounded-full filter blur-3xl opacity-80 z-2"/>

            </div>

        </div>

    </div>)

}

export default APISection
